body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8!important;
}
::-moz-selection { /* Code for Firefox */
  color: #fff;
  background: #55aa8b;
}

::selection {
  color: #fff;
  background: #55aa8b;
}
.w10{
  width: 10%;
}
.w5{
  width: 5%;
}
.w20{
  width: 20%;
}
.w50{
  width: 50%;
}
.w100{
  width: 100%;
}
.w90{
  width: 90%;
}
.w95{
  width: 95%;
}
.w80{
  width: 80%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  box-shadow: 2px 3px 10px 0px #ccc;
  border-radius: 5px;
  /* height: 80%; */
}

.text-blue {color: #0085FF!important;}
.text-red {color: #FF3D3D!important;}
.text-green {color: #55AA8B!important;}
.text-purple {color:#623BFF!important;}


.card-grey {
  background: #F6F6F6 !important;
  padding: 27px 0px 30px 29px;
  height: 300px;
}

.card-site:hover {
  cursor: pointer;
}

.card-primary {
  /* background: #B3DFD0!important; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s all ease-in;
}

.card-primary-2 {
  background-color: #55aa8b;
}
.MuiSwitch-track {
  background-color: #78a292ce!important;

}

.Mui-checked{
color: #43b189!important;
}
.card-primary-3 {
  background-color: #55aa8b85;
}

.card-primary-4 {
  background-color: #55aa8b57;
}

.card-primary-5 {
  background-color: #55aa8b5a;
}

.card-primary-6 {
  background-color: #55aa8b5a;
}


.card-primary-1 .MuiCardContent-root,
.card-primary-1 .MuiSvgIcon-root {
  color: #fff;
}

.pill-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 5px 0;
}

.pill {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
}

.pill span {
  margin-left: 5px;
}

.pill-green {
  background-color: rgba(85, 170, 139, 0.14);
}

.pill-yellow {
  background-color: rgba(255, 214, 0, 0.18);
}
.MuiDataGrid-iconSeparator{
  display: none!important ;
}
.MuiDataGrid-columnHeadersInner {
  background-color: #fff;
  border-radius: 5px;
}
.MuiDataGrid-columnHeaderTitle {
  font-family: 'Poppins' !important;
  font-weight: 700!important;
}
.MuiDataGrid-columnHeaders, .MuiDataGrid-virtualScrollerContent, .MuiDataGrid-root  {
  border: none !important;
}
.MuiDataGrid-row {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  /* margin-top: 0px; */
}
.MuiDataGrid-cell  {
  border-bottom: none!important;
  font-weight: 500;
  font-size: 15px;
}
.chart-info {
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  /* justify-content: space-between; */
  justify-content:space-around;
  padding-bottom: 30px;
}

.tons-txt{
  font-size: 14px;
  font-family: 'Roboto';
  color: #55AA8B !important;
}

.tonsin-number{
  color: #55AA8B !important;
  font-size: 25px;
  font-family: 'Poppins';
  font-weight:600;
}

.chart-info h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 59px;
  text-align: center;
  
  color: #06954C;
  margin: 0 !important;
}

.chart-info-sm {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%);
}

.chart-info-sm h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  color: #474747;
}


.chart-info p {font-style: normal;
  font-weight: 400;
  /* font-size: 20px;
  line-height: 23px; */
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.32);
  margin: 0 !important;
  font-family: 'Poppins';
}
* {
  outline: none!important;
}
.chart-wrapper {
  position: relative;

}

.sensor-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-top: 60px;
}

.sensor-box h1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 100px;
  line-height: 117px;
  text-align: center;

  color: #000000;
}

.sensor-box h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
}

.s-card h4 {
  font-weight: 700;
  font-size: 16px;
}

.s-info {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.card-site {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 4px;
  position: relative;
  margin-right: 20px;
  max-width: 178px;
  width: 100%;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow:none !important;
  border-radius: 6px !important;
}
.css-pwngrv-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
}

.bar {
  position: absolute;
width: 100%;
height: 4px;
left: 0px;
top: 0.02px;
background: #E7E7E7;
/* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
border-top-left-radius: 6px;
border-top-right-radius: 6px;
}
.active-bar{
  background: #55AA8B;
}

.inactive {
  background-color: rgb(255, 119, 119);
}
.active {
  background-color: rgb(106, 249, 141);
}
/* canva disply none */

.sensorcard-info{
  border: 5px solid #2C8943;
  border-radius: 50%;
  max-width: 100px;
  width: 100%;
  position: relative;
  height: 100px;
}
.bordertxt-center{
    position: absolute;
    bottom: 25%;
    right: 20%;
}
.carbon-categories{
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 500;
}
.carbon-values {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins';
  padding-left: 5px;
}
.dashed_img{
  margin-bottom: -13px;
}
.location-name{
  font-size: 10px;
  font-weight:600;
  font-family:'Poppins';
  color:#000;
}
.location-val{
  font-size: 26px;
  font-family: 'Poppins';
  font-weight: 700;
  color: #000;
  margin-top:4px;
  margin-bottom:4px;
}
.active-location-val{
  color: #55AA8B;
}
.slick-slide{
  width: 184px !important;
}
.sensor-count{
  font-size: 12px;
  color: #000;
  font-weight: 600;
  font-family: 'Poppins';
  padding-left: 9px;
}
.annual-report{
  background-color: #fff;
  width: 100%;
  padding: 12px 10px 15px 10px;
}
.css-1ygcj2i-MuiTableCell-root {
  padding: 1px !important;
}
.css-1ex1afd-MuiTableCell-root,
.css-1ygcj2i-MuiTableCell-root { 
font-size: 11px !important;
font-family: 'Poppins' !important;
padding: 7px !important;
}
button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-size: 14px !important;
  font-family: 'Poppins' !important;
}
.css-1g8wflw-MuiGrid-root>.MuiGrid-item {
  margin-bottom: 14px;
}
.css-pwngrv-MuiPaper-root-MuiCard-root {
  margin-bottom: 7px;
}
.hide-country{
  display: none;
}
.country-name{
  font-size: 14px;
  font-family:'Poppins';
  font-weight: 600;
  color: #979797;
}
.annual-rep{
  font-family:'Poppins';
  font-size: 14px;
  font-weight: 600;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.card.css-pwngrv-MuiPaper-root-MuiCard-root {
  margin-top: 7px;
} 
 /* .css-bhp9pd-MuiPaper-root-MuiCard-root {
  height:300px
}  */
.sensor-mapcontainer{
  height: 400px !important;
}
.form-area{
  padding: 10px px 25px 30px !important;
}
.image-section{
  position: relative;
}
.image-section {
  /* background: #55AA8B; */
  /* background-image: linear-gradient(180deg ,#55AA8B, yellow 0%, green 00%); */
  /* background-image: repeating-linear-gradient(#55AA8B, yellow 0%, green 0%); */
  /* height: 209px; */
  /* max-width: 319px; */
  /* width: 100%; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
/* .p-image {
  position: absolute;
  left: 25px;
  top: 90px;
} */
.user-location p {
  color: #fff;
  padding-top: 30px;
}
/* .user-image {
  padding-top: 30px;
} */
.user-name p {
  color: #000000;
  font-family:'Poppins';
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}
.user-name span {
  font-size: 12px;
  font-family: 'Poppins';
  color: #9C9C9C;
  font-weight: 400;
}
.social-icons {
  background-color: #E9E9E9;
  max-width: 265px;
  width: 100%;
  margin: auto;
  border-radius: 5px;
}
.street-name p {
  color: #565656;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 400;
  padding-top: 27px;
}
.user-image img {
  width: 100%;
  max-width: 179px;
}
.wrap-content {
  background: #fff;
  margin-top: 60px;
  padding: 30px;
}
.user-basicsdetails {
  padding-top: 15px;
}
.user-info span {
  font-size: 15px;
  color: #5A5A5A;
  font-weight: 500;
  font-family: 'Poppins';
}
.user-info p {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Poppins';
  color: #000000;
  padding-top: 11px;
}
.bottom-button{
  background: #FAFAFA;
    padding: 19px 0 21px 0;
    border-top: 1px solid #afafaf;
}
.doughnut-txt {
  /* position: absolute;
  top: 40px;
  z-index: 1;
  left: 130px; */
  position: absolute;
  top: 48px;
  z-index: 1;
  left: 127px;
}
.sensor-reportimg{
  max-width: 34px;
  width: 100%;
}
.sensor-locationico{
  max-width: 34px;
  width: 100%;
}
.satellite-ico{
  max-width: 34px;
  width: 100%;
}
.satellite-card {
  padding: 9px 0px 10px 29px;
}
.s-card p {
margin: 5px;
font-family: 'Poppins';
font-weight: 400;
font-size: 14px;
line-height: 24px;
color:#696969;
} 
.s-card span {
font-family: 'Poppins';
font-weight: 500;
font-size: 14px;
line-height: 24px;
color:#000000;
}
.elevation-card {
  margin-top: 10px;
}
.communication-tower {
  max-width: 34px;
  width: 100%;
}
.sansor-installedby{
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 15px;
align-items: center;
margin: 0;
color: #000000;
}
.installedby-mail{
font-family: 'Poppins';
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #545454;
margin: 0;
}
.sansor-details{
  padding-left: 10px;
}
.devider {
  border-top: 1px dotted #000;
  width: 90%;
  margin-top: 10px;
}
.maintenance-duedate p,
.date-ofinstall p{
  font-family: 'Poppins';
font-weight: 500;
font-size: 12px;
color: #696969;
margin-bottom: 0;
}
.maintenance-duedate button, .date-ofinstall button {
  color: #000000;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  border-radius: 3px;
  border: none;
  background: #fff;
}
.date-ofinstall,
.maintenance-duedate {
  text-align: center;
}
.card-blue{
  background-color:#58A5FF4A !important;
}
.date-ofinstall button {
  padding: 2px 5px 2px 5px;
}
.sansor-communication{
  margin-top: 10px;
}
.sensor-cards {
  margin-top: 8px;
}
.user-info label {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 122.5%;
color: #5A5A5A;
padding-bottom: 8px;
}
.user-info input {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  border: none;
  height: 35px;
  padding: 12px 16px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.user-info div {
  text-align: left;
}
.user-image {
  position: relative;
}
.user-profileview{
  position: absolute;
  top: 30px;
  left: 16px;
}
.dp-background img {
  max-width: 250px;
  width: 100%;
}
.user-name {
  padding-top: 25px;
}
select.form-select {
  background-color: #f3f3f3;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 40px;
  color: #55AA8B;
}
 option:hover {
  background-color: #55aa8b !important;
  color:#fff
}
 option:active {
  background-color: #55aa8b !important;
  color:#fff
}
 option:focus {
  background-color: #55aa8b !important;
  color:#fff
}
 option:visited {
  background-color: #55aa8b !important;
  color:#fff
}
 option:focus-within {
  background-color: #55aa8b !important;
  color:#fff
}
 option:focus-visible {
  background-color: #55aa8b !important;
  color:#fff
}
 
/* .form-select:focus{
  border-color: #55aa8b !important;
  background-color:#55aa8b;
} */
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url('../src//assets/icons/arrow_drop_down_24px.png'); 
}
label.form-label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #5A5A5A;
}
.from-input {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #868686;
  height: 40px;
}
.from-textarea{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #868686;
}
.form-top,
.mob-number {
  margin-top: 25px;
}
.form-top{
  margin-bottom: 25px;
}
.main-content {
  /* background-color: #fff; */
  border-radius: 4px;
  /* margin-top: 60px; */
}
.change-profilepic {
  margin-bottom: 40px;
}
.error{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #FF6565;
}
.site-areas {
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  background: transparent;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.addsites {
  position: relative;
}
.addsites span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 122.5%;
  color: #55AA8B;
  position: absolute;
  top: 15px;
  right: 0;
}
.site-text{
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 122.5%;
color: #5A5A5A;
}
.addsite-body {
  margin-top: 44px;
  align-items: baseline;
}
.add-sitebtn {
  margin-top: 44px;
}
.sites-content {
  background-color: #fff;
  padding: 33px;
}
.main-sidebar{
  color: 'red';
}
/* radio buttonncss */

.radio {
	padding: 4px;
	border-radius: 3px;
	position: relative;
  border: 1px solid #55AA8B;
  height: 36px;
}

.radio input {
	width: auto;
	height: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	cursor: pointer;
	border-radius: 2px;
	padding: 4px 8px;
	color: #bdbdbdbd;
	font-size: 14px;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	transition: all 100ms linear;
}

/* .radio input:checked {
color: #fff;
background: #55AA8B;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 18px;
color: #000000;
} */

.radio input:before {
	content: attr(label);
	display: inline-block;
	text-align: center;
	width: 100%;
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 10px;
color: #000000;
}
.radio-checked:checked{
  background-color: #43b189;
}
.card-items p {
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 400;
}
.card-items span {
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 600;
}
.sensor-box p {
  font-size: 40px;
  font-family: 'Poppins';
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.sensor-box span {
  font-size: 22px;
  font-family: 'Poppins';
  font-weight: 700;
}
/* .slick-prev {
  left: -15px;
  z-index: 1;
} */
.css-6rqjr4 {
  width: calc(100% - 0px) !important;
}
.css-uxa1ks-MuiListItem-root{
overflow: hidden;
}
.MuiDataGrid-cellContent {
  text-transform: capitalize;
}
.top-headerbar p {
  color: #666666;
  font-family: 'poppins';
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}
.top-headerbar span {
  color: #000000;
    font-family: 'poppins';
    font-size: 14px;
    font-weight: 400;
    line-height: 2;
}
.top-headerbar {
  margin-left: 4px;
}
.main-logo {
  padding-bottom: 50px;
}
.top-barinfo {
  padding-bottom: 40px;
  border-bottom: 1px solid #CECECE;
}
.top-heading {
  padding: 10px 0;
}
.top-heading h2{
  font-family: 'poppins';
  font-size: 18px;
    color: #233C2C;
    margin: 40px 0; 
}
.report-name h2 {
  padding: 50px 0;
  font-family: 'poppins';
  font-size: 30px;
  color: #233C2C;
  font-weight: 400;
}
table th {
  font-weight: 600;
  color: #000000;
  padding: 20px 8px;
  z-index: 100;
  font-family: 'poppins' !important;
}
.table td {
  font-weight: 400;
  color: #333333;
  padding: 20px 8px;
  z-index: 100;
  font-family: 'poppins';
}
.top-heading p {
  font-family: 'poppins';
  text-align: center;
  font-size: 22px;
  font-weight: 900;
}
.tbl-sell td,
.tbl-sell th a{
  font-family: 'poppins' !important;
  font-weight: 500;
}
.track-tblcell{
  text-transform:capitalize;
}
.carbon-title option,
.carbon-title{
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
}
ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list li span,
ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list li,
span,
.span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root,
.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft,
.MuiDataGrid-cellContent,
.MuiDataGrid-columnHeaderTitle.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  font-family: 'Poppins' !important;
  font-weight: 400;
}
.sm-doughnut {
  padding-left: 50px;
}
.site-slider {
  margin-left: 12px;
}
button{
  font-family: 'Poppins' !important;
}
.total-carboncount {
  text-align: center;
  padding: 20px 0;
}
.total-carboncount h2 {
  font-size: 30px;
  font-family: 'Poppins';
  font-weight: 500;
}
.total-carboncount p {
  font-size: 25px;
  font-family: 'Poppins';
  font-weight: 600;
  color: #55aa8b;
}
.css-19kzrtu{
  padding: 0 !important;
}
.tab_btn{
  max-height: 16px !important;
  padding: 8px !important;
  border-radius: 10px;
  font-size: 10px !important;
  font-family: 'Poppins' !important;
}
.graph-title p{
  transform: rotate(-90deg);
  font-size: 12px;
  /* margin-left: 10px; */
  padding: 0;
}
.graph-title {
  padding-top: 30px;
  text-align: center;
}
.daily-carboncount h2 {
  font-family: 'Poppins';
  font-size: 22px;
}
.daily-carboncount p {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 700 ;
  margin: 0;
  color: #55AA8B;
  margin-left: 20px;
}
.graphdiv{
  padding-top: 0px !important;
}
.report-name {
  margin-top: 50px;
}
.line-chart {
  position: relative;
}
.line-chart p {
  position: absolute;
  top: 248px;
}
.main-svg{
  background-color: transparent !important;
}
.wind-cart {
  position: relative;
}
.carbon-inkg{
  margin: 0;
  font-size: 10px;
  font-family: 'Poppins';
}
.weather-content{
  text-align: center;
}
.otp-input input {
  height: 40px;
  width: 100% !important;
}

  .otp-text h2 {
    font-size: 17px;
    font-family: 'Poppins';
    color: #000;
    font-weight: 700;
    text-align: center;
}
.otp-text {
  margin-top: 10px;
}
.otp-email{
  font-size: 12px !important;
  font-family: 'Poppins' !important;
  font-weight: 700 !important;
  text-align: center !important;
  margin: 0 !important;
  color: #000 !important;
  padding: 0 !important;
}
.otp-text p {
  font-size: 12px;
  font-family: 'Poppins';
  text-align: center;
  padding-top: 11px;
  margin: 0;
  color: #636363;
}
.otp-input {
  padding: 30px 0 30px 0;
}
.otp-reuqire {
  background: #FFE7E7;
  border: 1px solid #FF5C5C;
  border-radius: 5px;
}
.otp-reuqire p {
  margin: 0;
  padding: 10px;
  color: #000;
  font-weight: 400;
  font-family: 'Poppins';
  font-size: 12px;
}
.spam-folder{
  margin: 0;
  color: #636363;
  font-weight: 400;
  font-family: 'Poppins';
  font-size: 10px;
}

/* hide 3d */
/* g.infolayer {
  display: none;
} */
.card{
  border: none;
}
g.cbf5c959.colorbar {
  display: none;
}
g.cbfa1be8.colorbar {
  display: none !important;
}
.css-r11z79-MuiDataGrid-root .MuiDataGrid-cellContent{
  overflow:visible !important;
}
g.ycb61feeatick {
  display: none !important;
}
/* bottom menu css */
.bottom-menu{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: white;
  text-align: center;
  z-index: 1;
  overflow: scroll;
  display: none !important;
}
.report-input{
  width: 50%;
  margin: auto;
}
.main-sitediv {
  background: #fff;
  padding: 20px 40px 40px 40px;
  border-radius: 11px;
  margin-top: 10px;
}
.site-border {
  border-bottom: 4px solid #55AA8B;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 10%;
}
.multi-check {
  background-color: #fff;
  padding: 20px;
}
.linechart-heading {
  padding-top: 20px;
}
.linechart-heading h2{
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  
}
.check-getall {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins';
  padding-top: 8px;
  padding-bottom: 20px;

}
.check-catalog {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins';
    padding-top: 20px;
    padding-bottom: 10px;
}
.check-getall span,
.check-catalog span {
  font-family: 'Poppins' !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.8px;
  text-align: left;
}
.site-name p {
  font-family: 'Poppins';
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.download-site button {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.tbl-sitedetails p {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.tbl-sitedetails span {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.tbl-res h2 {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  
}
/* checkbox css */
.checkbox-container{
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
}

.td-firstchield{
  text-align: center !important;
  font-size: 16px !important;
   font-weight: 600 !important;
}
/* Hide the default checkbox */
.checkbox-container input {
  visibility: hidden;
  cursor: pointer;
}

/* Create a custom checkbox */
.mark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #55AA8B;
  border-radius: 4px;
}

.checkbox-container:hover input ~ .mark {
  background-color: #55AA8B;
}

.checkbox-container input:checked ~ .mark {
  background-color: #55AA8B;
}

/* Create the mark/indicator (hidden when not checked) */
.mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the mark when checked */
.checkbox-container input:checked ~ .mark:after {
  display: block;
}

/* Style the mark/indicator */
.checkbox-container .mark:after {
  left: 6px;
    top: 1px;
    width: 5px;
    height: 11px;
    border: 1px solid white;
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
}
.site-information {
  padding-top: 30px;
}
.site-names p {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.site-namedy p {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.report-siteinfo{
  width: 70%;
}
.report-sitemap h2,
.report-siteinfo h2{
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.report-sitemap ,
.report-siteinfo{
  margin-bottom: 00px !important;
}
.site-infoborder{
  border-bottom: 4px solid #55AA8B;
  width:15%;
  margin-top: 12px;
}
.report-images{
  width:70%;
  margin:auto;
  margin-top: 30px;
}
.report-sitemap{
  width: 30%;
}
/* .glossary-heading {
  padding: 0px 0 20px 0;
} */
@media print {
  .testclssss{
    display: none;
  }
}
.glossary-heading h2{
  font-family: 'Poppins';
font-size: 20px;
font-weight: 700;
line-height: 57px;
letter-spacing: 0em;
text-align: left;
 }
 .glossary-main h4{
  font-family: 'Poppins';
font-size: 14px;
font-weight: 700;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
text-transform: capitalize;
 }
 .glossary-main p{
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  
 }
 .tbl-head {
  background-color: #FAFAFA;
    border: 1px solid #D6D6D6;
}
.tbl-head th {
  padding: 14px;
  font-family: 'Poppins';
font-size: 14px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: left;

}
tr.tbl-bodytr {
  background-color: #fff;
  border: 1px solid #d6d6d6;
  
}

tr.tbl-bodytr td {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding: 11px;

}
tr.tbl-bodytr td:last-child{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
tr.tbl-bodytr td:first-child{
  border-top-left-radius: 5px;
 
  border-bottom-left-radius: 5px;
}

/* table tr:last-child td:last-child {
  border-bottom-right-radius:10px;
}

table tr:last-child td:first-child {
  border-bottom-left-radius:10px;
} */

table tr:first-child th:first-child,
table tr:first-child td:first-child {
  border-top-left-radius:5px
}

table tr:first-child th:last-child,
table tr:first-child td:last-child {
  border-top-right-radius:5px
}
/* .select-report {
  margin-top: 20px;
} */
.reset-btn {
  margin-right: 10px;
}
.not-founded {
  background: #fff;
  width: 20%;
  margin: auto;
  text-align: center;
  border-radius: 5px;
  padding: 30px;
  position: absolute;
top: 43%;
left: 43%;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.reset-btn button {
  background: #FF6262;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding: 3px;
}
.not-founded p{
  font-family: 'Poppins';
font-size: 17px;
font-weight: 600;
line-height: 26px;
letter-spacing: 0em;
text-align: center;
}
.not-founded button {
  background: #55AA8B;
  border: none;
  border-radius: 4px;
  padding: 7px 15px 7px 15px;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #fff;
  text-align: left;
}
.dropdown-heading-value span,
  .select-report p {
    padding: 0px;
    color: #55AA8B;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
}
.rmsc .dropdown-heading,
.dropdown-container {
  background-color: #f3f3f3;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 40px; 
  color: #55AA8B;
}
.sitea-report {
  padding-bottom: 10px;
}
.rmsc .gray{
  color: #55AA8B;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.glossary-border{
  border-bottom: 4px solid #55AA8B;
  margin-top: 38px;
  margin-bottom: 8px;
  max-width: 63px;
  width: 100%;
  }
  
  /* spicial table record */
  .repot-tblcard{
    font-family: 'Poppins';
  font-size: 10px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 5px;
  }
  .report-tblbody{
    border-bottom: 1px solid #F0F0F0;
  }
  .tbl-reportres{
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
  }
  .report-date{
    font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  
  }
  .tbl-reportres span {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 10px;
  } 
  .reporttbl-head {
    background-color: #FAFAFA;
      border: 1px solid #D6D6D6;
  }
  .reporttbl-head th {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;  
  }
  .reportmain-sitediv {
    background: #fff;
    padding: 10px 13px 10px 12px;
    border-radius: 11px;
    margin-top: 10px;
  }
  .middle-border{
    top: 50%;
    left: 47.5%;
  }
  .report-setting{
    border-radius: 50%;
  }
  .middle-border::before {
    content: '';
      position: absolute;
      width: 110%;
      height: 2px;
      top: 31px;
      left: -63px;
      border-bottom: 1.5px dotted black;
      z-index: -1;
  }
  .middle-border::after {
    content: '';
    position: absolute;
    /* background-color: pink; */
    width: 130%;
    height: 2px;
    top: 31px;
    left: 60px;
    /* border-style: dotted; */
    border-bottom: 1.5px dotted black;
    z-index: -1;
  }
  .fluxreport p{
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding: 20px 30px 20px 30px;
  margin: 0;
  }
  .fluxData p{
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
  .fluxreport {
      background: #FF8787;
      border-radius: 2px;
  }
  .corrected-flux{
    background: #6FEABD;
    border-radius: 2px;
  }
  .fluxData p {
    margin: 0;
    font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  }
  .flux-section{
    margin-top: 10px;
  }
  .dashcard-gray{
    height: auto !important;
  }
  .random-flux{
    font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  }
  .ai-ml{
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  position:absolute;
  top: 10px;
  }
  .flux-tbl tr th{
    padding: 10px !important;
  }
  .reporttbl-body{
    text-align: center;
  }
  .without-i{
    width: 45%;
  }
  .with-i{
    width: 45%;
  }
  .poligon-fluxtop {
    position: absolute;
    top: 19px;
    left: -16px;
}
.poligon-fluxleft {
  position: absolute;
  top: 19px;
  left: 68px;
}
iframe.iframe-grafana {
  height: 100vw;
}
.to-to-from{
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 500;
}
.zonal-statistics{
  margin-bottom: 15px !important;
  font-family: 'Poppins' !important;
font-size: 16px !important;
font-weight: 600 !important;
line-height: 24px !important;
letter-spacing: 0em !important;
text-align: left !important;
}
.zone-section{
  margin-top: 30px;
}
.analysis-graph {
  margin-top: 30px;
}
.zone-border1,.zone-border2 {
  border-bottom: 1px solid #E0E0E0;
  margin-top: 40px;
}
.projects-heading {
  background: #fff;
  border-radius: 5px;
}
p.accordion-heading {
  margin: 0;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
p.if-applicable {
    margin: 0;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}
.btnview-all button {
  background: transparent;
  border: 1px solid #FFA0A0;
  border-radius: 4px;
  padding: 8px 30px;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #55AA8B;
}
.btnadd button {
  background: #55AA8B;
  border: none;
  border-radius: 4px;
  padding: 8px 42px;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}
.btnadd,
.btnview-all {
  margin: 10px;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  box-shadow: none !important;
  border-radius: 10px !important;
}
.search-input {
  position: relative;
}
.search-input input {
  height: 35px;
  border: 1px solid #D4D4D4;
  outline: none;
  border-radius: 4px;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 10px;
  color: #A3A3A3;
}
.search-ico {
  position: absolute;
  top: 4px;
  right: 8px;
  color: #DADADA;
}
.add-projectbtn button {
  background: #55AA8B;
  color: #FEFEFE;
  border: none;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 8px 18px;
  border-radius: 4px;
}
.search-input,
.add-projectbtn {
  margin: 20px 0px 20px 10px;
}
.projects-info {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
}
h2.rowmain-heading {
  padding: 21px 31px;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
.project-label {
  padding: 0px 20px 30px 31px;
}
h1.siteheading {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #474747;
}
.siteheading-div {
  margin-bottom: 30px;
}
.project-label label {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #5A5A5A;
  padding-bottom: 7px;
}
.project-label input {
  outline: none;
  border: 1px solid #E6E6E6;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px 10px;
  background: #E6E6E6;
  color: #000;
}
.project-label p {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin: 5px;
  color: #595959;
}
.project-label button {
  background: transparent;
  border: none;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #55AA8B;
  text-decoration: underline;
}
.sub-fieldcheck p {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #5A5A5A;
  margin: 0;
  padding-bottom: 20px;
}
.sub-fieldcheck label {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #55AA8B;
}
.sub-fieldcheck {
  padding: 0px 20px 30px 31px;
}
.delete-ico button {
  color: #FF7272;
  background: #e97a801f;
  border: none;
  border-radius: 4px;
  padding: 7px 12px;
}
.delete-ico {
  margin: 7px;
}
.edit-projects input {
  background: transparent;
  border: 1px dashed #9E9E9E;
}
.edit-projects label {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #5A5A5A;
}
.edit-projects {
  padding: 0px 15px 20px 15px;
  position: relative;
}
.edit-projects select {
  background: none;
  border: 1px dashed #9E9E9E;
}
.arrowdown-ico {
  position: absolute;
  top: 37px;
  right: 28px;
  color: #55AA8B;
}
a.shape-link {
  color: #55AA8B;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.shape-delico {
  color: #FF7272;
}


.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #55aa8b17;
  /* box-shadow: 0px 0px 2px rgb(0 0 0 / 25%); */
  border: 1px dashed #55AA8B;
  width: 100%;
}
span.shape_filefalse {
  color: #FF7272;
}
span.shape_filetrue {
  color: rgb(85, 170, 139);
}
.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}
.sure_to-delete{
  font-family: 'Poppins';
font-size: 18px;
font-weight: 600;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
}
.sure_to-no{
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #FF7272;
}
.sure_to-yes{
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #55aa8b;
}
button.shape-link {
  background: transparent;
  border: none;
  color: #55AA8B;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
}
.card-status {
  background: #88E94D;
  height: 10px;
  width: 40px;
  border-radius: 40px;
}
.card-status-pink{
  background: #FF6262;
  height: 10px;
  width: 40px;
  border-radius: 40px;
}
.card-status-yellow{
  background: #F8F14E;
  height: 10px;
  width: 40px;
  border-radius: 40px;
}
.card-status-blue{
  background: #0A2EAE;
  height: 10px;
  width: 40px;
  border-radius: 40px;
}
.card-status-green{
  background: #259E43;
  height: 10px;
  width: 40px;
  border-radius: 40px;
}
.card-status-lightpink{
  background: #FF964B;
  height: 10px;
  width: 40px;
  border-radius: 40px;
}
.card-status-lightblue{
  background: #4B72FF;
  height: 10px;
  width: 40px;
  border-radius: 40px;
}
.redirect-nav{
  cursor: pointer
}
.project-cards {
  background: #fff;
  width: 20%;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
}
.dropbtn {
  color: #D9D9D9;
  border: none;
  background: transparent;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content1 {
  border-radius: 4px;
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-content1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content1 a:hover {background-color: #ddd;}
.dropdown:hover .dropdown-content1 {display: block;}
.project-cards {
  background: #fff;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.project-heading h1 {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #242424;
}
.cardsection-one{
  border-bottom: 1px dashed #DBDBDB;
}
p.cardp-txt {
  padding-left: 10px;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #242424;
  margin: 12px 0px 10px 0px;
}
.dropdown-content1 button {
  background: transparent;
  width: 100%;
  border: none;
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.viewproject-status select {
  background: #fff;
  height: 35px;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  border-radius: 4px;
}
button.dropbtn-status {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  height: 35px;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.card-pink {
  background: #FF6262;
  height: 7px;
  width: 15px;
  border-radius: 40px;
}
.card-yellow {
  background: #F8F14E;
  height: 7px;
  width: 15px;
  border-radius: 40px;
}
.card-green{
  background: #259E43;
  height: 7px;
  width: 15px;
  border-radius: 40px;
}
.card-tamoto{
  background:#FF964B;
  height: 7px;
  width: 15px;
  border-radius: 40px;
}
.card-blue{
  background:#4B72FF;
  height: 7px;
  width: 15px;
  border-radius: 40px;
}
.card-darkblue{
  background:#0A2EAE;
  height: 7px;
  width: 15px;
  border-radius: 40px;
}
.card-lightgreen{
  background:#88E94D;
  height: 7px;
  width: 15px;
  border-radius: 40px;
}
/* .dropdown-content{
  display: block !important;
} */
.radio-border {
  border: 1px dashed;
  border-radius: 5px;
  padding: 4px;
}

@media screen and (min-width: 220px) {
  .track-card, 
  .map-grid {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .card-grey {
    height: 350px;
  }
  .without-i{
    width: 100%;
  }
  .with-i{
    width: 100%;
  }
  .middle-border{
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .form-body{
    border-radius: 0;
  }
  .sign-in-bg{
    background-image: none !important;
    background: #fff;
  }
  .login-container {
    max-width: 100% !important;
    margin: 0;
    padding: 0;
}
.report-input{
  width:100%;
}

.MuiListItemText-root.css-1cag644-MuiListItemText-root {
  display: none;
}
.MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root.css-1fqi8hc-MuiButtonBase-root-MuiListItemButton-root {
  padding: 10px;
}
.bottom-menu{
  display: block !important;
}
.bordertxt-center {
  right: 23%;
}
.mobilemenu-items {
  margin: auto;
  max-width: 50px;
  width: 100%;
}
.menu-logo{
  text-align: center;
}
.main-containerbody {
  margin-top: 50px;
}
}
@media screen and (max-width: 600px){
  .drawer-sidemenu{
    display: none;
  }
  .mobilemenu-items p {
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 500;
}
  .top-itemnav{
    width: 100%;
    margin-top: 20px;
  }
  .top-barinfo{
    width: 50%;
    margin: auto;
  }
  .mylogo {
    text-align: center;
}
.mylogo h2 {
  font-size: 22px;
  text-align: center;
}
.pdf-btn{
  width: 100%;
}
.main-logo {
  width: 100%;
  padding-bottom: 24px;
}
.daily-carboncount h2 {
  font-family: 'Poppins';
  font-size: 17px;
}
.daily-carboncount p {
  font-size: 20px;
}
.top-barinfo{
  border: none;
}
.report-name h2 {
  font-size: 22px;
  text-align: center;
}
table th {
  font-size: 11px;
}
}
