body{background-position: center center; background-repeat: no-repeat;background-size: cover;min-height: 100vh; color: #7E7E7E;font-weight: 400;
font-size: 14px;
line-height: 21px;
  font-family: 'Poppins', sans-serif;}
a{color: #7e7e7e;text-decoration: none;}
body .overlay{ background-repeat: no-repeat;} 

.h-100vh{min-height: 100vh;}     

/*--------background----------*/
.form-body{background: #FFFFFF;
  box-shadow: 0px 81px 101px rgba(0, 0, 0, 0.08), 0px 24.4191px 30.4485px rgba(0, 0, 0, 0.0521271), 0px 10.1425px 12.6468px rgba(0, 0, 0, 0.04), 0px 3.66833px 4.57409px rgba(0, 0, 0, 0.0278729);
  border-radius: 16px;}
.green-light-bg{background-color: #55AA8B;}
.light-blue-bg{background-color: #079DAB;}
.dark-blue-bg{background-color: #21658B;}

/*------text-------*/
.weight-semibold{font-weight: 600;}
.text-white{color: #fff;}



/*---------form css------------*/
.form-signin {
  margin-right: 150px;
}
.form-signin h1{font-weight: 500;
font-size: 16px;
line-height: 24px;color: #043927;}
.form-signin .form-group{display: flex; align-items: baseline;
    padding: 15px 15px 15px 15px;
background: #F6F6F6;
/* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15); */
border: 1px solid #d6d6d6;
border-radius: 5px;
outline: none!important;}
.form-control {
  box-shadow: none!important;
}

.form-signin .form-group input{font-weight: 300;background: transparent;padding:0 0 0 10px;
font-size: 14px;
line-height: 21px;border: none;}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
}

.divider::after,
.divider::before {
  content: "";
  border: 1px solid rgba(126, 126, 126, 0.3);
  flex: 1;
}

.divider:not(:empty)::before {
  margin-right: 1em;
}

.divider:not(:empty)::after {
  margin-left: 1em;
}

.number-container{
  font-size: 11.8px;
  font-family:'Poppins';
}

.cardsensor-metrics{
  font-size: 14px;
  font-weight: 600;
  font-family:'Poppins';
  color:'#000000';
}
@media only screen and (max-width: 768px) {
  .form-signin {
    margin-right: 0px;
  }
}